import { Component, OnInit, AfterViewInit } from '@angular/core';
import { from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { saveAs } from "file-saver";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  baseUrl: string = "https://io.freshapps.nl/api/v1/passes/generate";
  storeUrl: string = "https://io.freshapps.nl/api/v1/campaigns/url/";
  passCreated: Boolean = false;
  public myAngularxQrCode: string = null;
  storeShortUrlId: string;
  private var2: string;
  private sub: Subscription;
  store: { userId: 0, passTypeIdentifier: '', hero: '', logo: '', name: '', type: '' };
  nameRequired: Boolean = false;
  emailRequired: Boolean = false;
  phoneRequired: Boolean = false;
  bdayRequired: Boolean = false;
  today = formatDate(new Date(), 'dd/MM/yyyy', 'en');

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router) {
  }
  ngOnInit() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    this.route.pathFromRoot[1].url.subscribe(val => {
      this.storeShortUrlId = val[1].path;
      this.http.get(this.storeUrl + this.storeShortUrlId, { headers }).subscribe((data: any) => {
        this.store = data.campaign;
      }, error => {
        //redirect the user to freshwallet.nl
        //window.location.href = 'http://www.freshwallet.nl';
        console.log("Error", error);
      });
    });
  }

  ngAfterViewInit() {
    console.log('ng after view init fired');

  }

  onClickSubmit(formData) {
    if (formData.name == "") {
      console.log(formData);
      this.nameRequired = true;
      return;
    } else {
      this.nameRequired = false;
    }

    if (formData.email == "") {
      console.log(formData);
      this.emailRequired = true;
      return;
    } else {
      this.emailRequired = false;
    }

    if (formData.phonenumber == "") {
      console.log(formData);
      this.phoneRequired = true;
      return;
    } else {
      this.phoneRequired = false;
    }

    if (formData.bday == "") {
      console.log(formData);
      this.bdayRequired = true;
      return;
    } else {
      this.bdayRequired = false;
    }

    var userObject = { storeId: this.store.userId, campaignId: 1, name: formData.name, email: formData.email, phonenumber: formData.phonenumber, bday: formData.bday, recoverEmail: formData.recoverEmail };
    this.http.post(this.baseUrl, JSON.stringify(userObject), {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    })
      .subscribe(
        (data: any) => {
          this.getShortUrl(data);
        }, error => {
          console.log("Error", error);
        });

  }

  getShortUrl(data) {
    //generate the link for the api
    let linkRequest = {
      destination: "https://io.freshapps.nl/api/v1/passes/" + data.serialNumber,
      domain: { fullName: "wllt.nl" }
    }

    let requestHeaders = {
      "Content-Type": "application/json",
      "apikey": "0f3b47ae5cbc4c31bb88bd5add84fb7f",
      "workspace": "9ee4106938c6427f8db6fb23a29cc097"
    }

    this.http.post("https://api.rebrandly.com/v1/links", JSON.stringify(linkRequest), {
      headers: requestHeaders
    }).subscribe((data: any) => {
      console.log(data.shortUrl);
      this.passCreated = true;
      this.myAngularxQrCode = "http://" + data.shortUrl;

    }, error => {
      //redirect the user to freshwallet.nl
      //window.location.href = 'http://www.freshwallet.nl';
      console.log("Error", error);
    });
  }

  downloadPass(platform) {
    if (platform == 'ios') { 
      console.log(this.myAngularxQrCode);
      window.location.href = this.myAngularxQrCode;
    } else {
      console.log(this.myAngularxQrCode);
      window.location.href = 'https://passwallet.page.link/?apn=com.attidomobile.passwallet&link=' + this.myAngularxQrCode;
    }
  }
}
