import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { routing, mainRoutingProviders } from './main.route'; // We also import our Routes
import {RootComponent}  from './root.component'; // we import our new RootComponent

@NgModule({
  declarations: [
    AppComponent,
    RootComponent
  ],
  imports: [
    QRCodeModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    mainRoutingProviders,
    routing
  ],
  providers: [],
  bootstrap: [RootComponent]
})
export class AppModule { }
